var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1000","fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-border-card',{attrs:{"title":_vm.$t('appointments.invoices')},on:{"card_clicked":function($event){_vm.fetch;
        _vm.dialog = true;}}})]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("appointments.invoices"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"5","sm":"3","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"daymenu",class:{ 'error--text': _vm.filters.from > _vm.filters.to },attrs:{"label":_vm.$t('from'),"outlined":"","dense":"","hide-details":"","value":_vm.filters.from
                    ? _vm.$d(new Date(_vm.filters.from), 'date2digits')
                    : null,"readonly":"","clearable":""},on:{"click:clear":function($event){_vm.filters.from = null}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mt-1",attrs:{"small":""}},[_vm._v("$appointments")])]},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu.first_day),callback:function ($$v) {_vm.$set(_vm.menu, "first_day", $$v)},expression:"menu.first_day"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","color":"primary","max":_vm.filters.to,"no-title":""},on:{"input":function($event){_vm.menu.first_day = false}},model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"5","sm":"3","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"daymenu",class:{ 'error--text': _vm.filters.from > _vm.filters.to },attrs:{"label":_vm.$t('to'),"outlined":"","dense":"","hide-details":"","value":_vm.filters.to ? _vm.$d(new Date(_vm.filters.to), 'date2digits') : null,"readonly":"","clearable":""},on:{"click:clear":function($event){_vm.filters.to = null}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mt-1",attrs:{"small":""}},[_vm._v("$appointments")])]},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu.last_day),callback:function ($$v) {_vm.$set(_vm.menu, "last_day", $$v)},expression:"menu.last_day"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","color":"primary","min":_vm.filters.from,"no-title":""},on:{"input":function($event){_vm.menu.last_day = false}},model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1)],1),_c('v-spacer'),_c('New',{ref:"new",attrs:{"appointment":_vm.appointment},on:{"update":_vm.fetch}})],1)],1),_c('Table',{ref:"table",attrs:{"filters":Object.assign({}, _vm.filters, {appointment_id: this.appointment.id}),"headers":_vm.headers},on:{"empty2":_vm.openForm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template v-slot:activator="{ on }">
      <base-border-card
        :title="$t('appointments.invoices')"
        @card_clicked="
          fetch;
          dialog = true;
        "
      >
      </base-border-card>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("appointments.invoices") }}
      </v-card-title>

      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="5" sm="3" md="2" class="py-0">
            <v-menu
              v-model="menu.first_day"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="$t('from')"
                  class="daymenu"
                  outlined
                  dense
                  hide-details
                  :value="
                    filters.from
                      ? $d(new Date(filters.from), 'date2digits')
                      : null
                  "
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-bind:class="{ 'error--text': filters.from > filters.to }"
                  clearable
                  @click:clear="filters.from = null"
                >
                  <template v-slot:prepend-inner
                    ><v-icon small class="mt-1">$appointments</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="filters.from"
                color="primary"
                :max="filters.to"
                no-title
                @input="menu.first_day = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5" sm="3" md="2" class="py-0">
            <v-menu
              v-model="menu.last_day"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="$t('to')"
                  class="daymenu"
                  outlined
                  dense
                  hide-details
                  :value="
                    filters.to ? $d(new Date(filters.to), 'date2digits') : null
                  "
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-bind:class="{ 'error--text': filters.from > filters.to }"
                  clearable
                  @click:clear="filters.to = null"
                >
                  <template v-slot:prepend-inner
                    ><v-icon small class="mt-1">$appointments</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="filters.to"
                color="primary"
                :min="filters.from"
                no-title
                @input="menu.last_day = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer />
          <New :appointment="appointment" @update="fetch" ref="new" />
        </v-row>
      </v-card-text>
      <Table
        ref="table"
        :filters="{ ...filters, appointment_id: this.appointment.id }"
        :headers="headers"
        @empty2="openForm"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "InvoicesAppointment",
  components: {
    New: () => import("@/components/invoices/NewInvoiceAppointment"),
    Table: () => import("@/components/invoices/Table"),
  },
  props: ["appointment"],
  data() {
    return {
      dialog: false,
      filters: {},
      items: [],
      options: {},
      headers: [
        {
          text: this.$t("invoices.number"),
          sortable: false,
          value: "number",
          align: "center",
        },
        {
          text: this.$t("invoices.date"),
          sortable: false,
          value: "date",
          align: "center",
        },
        {
          text: this.$t("invoices.from"),
          sortable: false,
          value: "from",
          align: "center",
        },
        {
          text: this.$t("invoices.to"),
          sortable: false,
          value: "to",
          align: "center",
        },
        {
          text: this.$t("invoices.total"),
          sortable: false,
          value: "total_price",
          align: "center",
        },
        /*
        {
          text: this.$t("payment_method"),
          sortable: false,
          value: "payment_method",
          align: "center",
        },
        */
        {
          text: this.$t("actions"),
          sortable: false,
          value: "actions",
          align: "center",
        },
      ],
      menu: {
        first_day: false,
        last_day: false,
      },
    };
  },
  methods: {
    ...mapActions("invoices", ["getInvoices"]),
    fetch() {
      console.log("FETCH INVOICES");
      this.$refs.table.fetchInvoices();
    },
    openForm() {
      console.log("OPEN FORM FACTURA");
      this.$refs.new.open();
    },
  },
};
</script>
